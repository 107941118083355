@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.nodeTreeWrapper {
  ul {
    list-style: none;
    margin: 0;
    li {
      padding: 0 0 0 1rem;
      &:before {
        content: "";
        position: absolute;
        top: -0.5rem;
        left: 0;
        border-left: 1px solid $gray-400;
        width: 1px;
        height: 100%;
      }

      &:after {
        content: "";
        position: absolute;
        border-top: 1px solid $gray-400;
        top: 1.555rem;
        left: 0;
        width: 1rem;
      }

      &:last-child:before {
        height: 33px;
      }
      &.summary {
        padding: 0 0 0 0.3rem;

        &:after {
          width: 0.3rem;
        }
        &.product {
          &:after {
            top: 1.1rem !important;
          }
        }
      }
    }
  }
}

.rootNode {
  text-align: center;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
  ul {
    margin: 0;
  }
}
.overItem {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    border-bottom: 2px solid $blue-300;
    left: 0;
    width: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    border: 2px solid $blue-300;
    width: 0.5rem;
    aspect-ratio: 1;
    border-radius: 50%;
    bottom: -0.2rem;
    left: -0.5rem;
  }
  &Before {
    &:before {
      top: -0.1rem;
    }
    &:after {
      top: -0.3rem;
    }
  }
  &After {
    &:before {
      bottom: 0;
    }
    &:after {
      bottom: -0.2rem;
    }
  }
}
.productItem {
  &:after {
    top: 2.5rem !important;
  }
  &Min {
    &:after {
      top: 1.255rem !important;
    }
  }
  &:last-child:before {
    height: 48px !important;
  }
  &Min:last-child:before {
    height: 26px !important;
  }

  .trimmed {
    max-width: 105px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover .trimmed {
    white-space: normal;
    overflow: hidden;
    text-overflow: clip;
    max-width: 100%;
  }
}

.blur {
  filter: blur(1px);
  opacity: 0.7;
}

.folder {
  &Top {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2222;
  }

  &Before {
    height: 8px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    background: rgba(105, 128, 0, 0.2);
  }

  &After {
    height: 8px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: rgba(0, 128, 0, 0.2);
  }
}

.wizardProduct {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 4px;
    background: $blue-300;
    border-radius: 1px;
    top: 1px;
    left: 1px;
  }
  &.summary {
    &:before {
      height: 100%;
    }
  }
}
