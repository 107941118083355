@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

$extendedWidth: 420px;
$sidebarTransition: 0.3s ease-in-out max-width;

.rightSidebar {
  &Wrapper {
    max-width: 0;
    max-height: calc(100vh - 79px);
    box-shadow: 0 6px 6px 2px rgba(0, 0, 0, 0.15);
    transition: $sidebarTransition;

    &Extended {
      max-width: 20.75rem;
      transition: $sidebarTransition;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: $gray-700;
        z-index: 2;
      }
    }
  }

  &Toggle {
    width: 32px;
    padding: 10px 6px;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 100%;
    top: 0;
    z-index: 2;

    :global {
      .svg-inline--fa {
        transition: 0.2s all;
        color: white;
      }
    }

    &Extended {
      :global {
        .svg-inline--fa {
          transform: rotateZ(180deg);
        }
      }
    }
    & span {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }
}
